import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

ValidItem.propTypes = {
  collection: PropTypes.array.isRequired,
  itemId: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  children: PropTypes.func
};

export default function ValidItem ({ collection, itemId, redirect = '/', children }) {
  const item = collection.find(item => item.id === itemId);
  return (
    (!item)
      ? <Redirect to={redirect} />
      : children(item)
  );
}
