import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as ManagersIcon } from './artists.svg';
import { ReactComponent as BandsIcon } from './bands.svg';
import { ReactComponent as ProjectsIcon } from './projects.svg';
import { ReactComponent as AnalyticsIcon } from './analytics.svg';
import { ReactComponent as ToolsIcon } from './tools.svg';
import { ReactComponent as MerchandiseIcon } from './merchandise.svg';

import styles from './orgNav.styles';

function NavItem ({ classes, org, permissions, icon, children, ...rest }) {
  return (
    <ListItem className={clsx(classes.item)} component={NavLink} {...rest}>
      <ListItemIcon className={classes.itemIcon}>
        {icon}
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.itemPrimary,
        }}
      >
       {children}
      </ListItemText>
    </ListItem>
  )
}

function Navigation({ org, orgUrl, classes, features, ...rest }) {
  return (
    <Drawer variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.paper,
        paperAnchorDockedLeft: classes.docked
      }} {...rest}>

      <Toolbar className={classes.toolBar} />

      <List className={classes.list} disablePadding>
        { <NavItem
          icon={<HomeIcon />}
          classes={classes}
          org={org}
          exact
          to="/orgs">Home</NavItem> }

        { org.orgRole >= 100 && <NavItem
          icon={<ManagersIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/members`}>Managers</NavItem> }

        { <NavItem
          icon={<BandsIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/divisions`}>Bands</NavItem> }

        { <NavItem
          icon={<ProjectsIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/projects`}>Projects</NavItem> }

        { <NavItem
          icon={<AnalyticsIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/analytics`}>Analytics</NavItem> }

        { <NavItem
          icon={<ToolsIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/tools`}>Tools</NavItem> }

        { <NavItem
          icon={<MerchandiseIcon />}
          classes={classes}
          org={org}
          to={`${orgUrl}/merchandise`}>Merchandise</NavItem> }
        { /* features.filter(f => !f.parent).map(feature => (
          <NavItem
            org={org}
            icon={<HomeIcon />}
            key={feature.slug}
            permissions={feature.permissions}
            classes={classes}
            to={`${orgUrl}/${feature.slug}`}>{feature.name}</NavItem>
        )) */ }
      </List>
    </Drawer>
  );
}

OrgNav.propTypes = {
  orgUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

function OrgNav ({ org, orgUrl, features, classes }) {
  const [open, setOpen] = useState(false);
  function handleDrawerToggle () {}

  return (
    <nav className={classes.drawer}>
      { features && <Navigation
        classes={classes}
        features={features}
        org={org}
        orgUrl={orgUrl} /> }
    </nav>
  );
}

export default styles(OrgNav);
