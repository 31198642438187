import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

import { db } from 'config';
import { useSessionState } from 'providers/Session';
import { useProvider, useProviderData } from 'hooks/useProvider';

const ItemStateContext = React.createContext();
const ItemDispatchContext = React.createContext();

function loadAllItems (feature, parentId) {
  return db.collection(feature.collection)
    .where("parentId", "==", parentId).get()
    .then(query => {
      return query.docs.map(doc => (
        { ...doc.data(), id: doc.id }
      ))
    })
}

function loadMemberItem (collection, itemId) {
  return db.doc(`${collection}/${itemId}`).get()
    .then(doc => (
      (doc.exists)
        ? { ...doc.data(), id: doc.id }
        : null
    ));
}

function loadMemberItems (feature, userId, parentId) {
  return db.collection(`members_${feature.collection}/${userId}/${feature.collection}`)
    .where("parentId", "==", parentId).get()
    .then(query => {
      return Promise.all(query.docs.map(doc => (
        loadMemberItem(feature.collection, doc.id)
      )))
    });
}

function loadItems (feature, userId, orgRole, parentId, dispatch) {
  const { permissions } = feature;
  let promise;

  switch (permissions) {
    case 'open':
      promise = loadAllItems(feature, parentId);
      break;
    case 'assigned':
      promise = (orgRole >= 100)
        ? loadAllItems(feature, parentId)
        : loadMemberItems(feature, userId, parentId)
      break;
    default:
      promise = (orgRole >= 100)
        ? loadAllItems(feature, parentId)
        : Promise.reject('insufficient permission to view these items.')
  }

  return promise
    .then(items => dispatch({ type: ITEM.FETCH_ITEMS_SUCCESS, items }))
    .catch(error => dispatch({ type: ITEM.FETCH_ITEMS_FAILURE, error }));
}

function createSingletonItem (feature, item, parentId) {
  const ref = db.collection(feature.collection).doc(parentId)

  // return ref to match collection add method
  return ref.set(item)
    .then(() => ref)
}

function createCollectionItem (feature, item) {
  return db.collection(feature.collection).add(item)
}

export function createItem (feature, item, parentId, dispatch) {
  dispatch({ type: ITEM.CREATE_ITEM_REQUEST });

  const newItem = { parentId, ...item };
  const promise = (feature.singleton)
    ? createSingletonItem(feature, newItem, parentId)
    : createCollectionItem(feature, newItem);

  return promise
    .then(doc => dispatch({ type: ITEM.CREATE_ITEM_SUCCESS, item: { id: doc.id, ...newItem } }))
    .catch(error => dispatch({ type: ITEM.CREATE_ITEM_FAILURE, error }));
}

export function updateItem(feature, item, dispatch) {
  const { id, ...data } = item;
  dispatch({ type: ITEM.UPDATE_ITEM_REQUEST })
  return db.collection(feature.collection).doc(id)
    .update(data)
    .then(doc => dispatch({ type: ITEM.UPDATE_ITEM_SUCCESS, item }))
    .catch(error => dispatch({ type: ITEM.UPDATE_ITEM_FAILURE, error }));
}

function deleteChildFeatureItems (batch, features, feature, itemId) {
  return db.collection(feature.collection).where('parentId', '==', itemId)
    .get().then(query => {
      return (query.size)
        ? query.docs.map(doc => deleteAllRecursively(batch, features, feature, doc.id))
        : [Promise.resolve()];
    })
}

function deleteAllChildFeatureItems (batch, features, feature, itemId) {
  const childFeatures = features.filter(f => f.parent === feature.id);
  if (childFeatures.length) {
    return Promise.all(childFeatures.map(child => {
      return (child.collection)
        ? Promise.all(deleteChildFeatureItems(batch, features, child, itemId))
        : [Promise.resolve()];
    }))
  }

  return Promise.resolve();
}

function deleteAllRecursively (batch, features, feature, itemId) {
  const ref = db.collection(feature.collection).doc(itemId);
  batch.delete(ref);

  return deleteAllChildFeatureItems(batch, features, feature, itemId)
}

export function deleteItem (features, feature, itemId, dispatch) {
  const batch = db.batch();

  return deleteAllRecursively(batch, features, feature, itemId)
    .then(() => {
      console.log(batch.commit());
    })
}

const ITEM = {
  FETCH_ITEMS_REQUEST: 'FETCH_ITEMS_REQUEST',
  FETCH_ITEMS_FAILURE: 'FETCH_ITEMS_FAILURE',
  FETCH_ITEMS_SUCCESS: 'FETCH_ITEMS_SUCCESS',
  CREATE_ITEM_REQUEST: 'CREATE_ITEM_REQUEST',
  CREATE_ITEM_FAILURE: 'CREATE_ITEM_FAILURE',
  CREATE_ITEM_SUCCESS: 'CREATE_ITEM_SUCCESS',
  UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS'
};

function itemDataReducer (state, action) {
  switch (action.type) {
    case ITEM.FETCH_ITEMS_SUCCESS:
      return action.items;
    case ITEM.CREATE_ITEM_SUCCESS:
      return state.concat([action.item]);
    case ITEM.UPDATE_ITEM_SUCCESS:
      return state.map(item => (item.id !== action.item.id) ? item : action.item);
    default:
      return state;
  }
}

function itemErrorReducer (action) {
  switch (action.type) {
    case ITEM.FETCH_ITEMS_FAILURE:
    case ITEM.CREATE_ITEM_FAILURE:
    case ITEM.UPDATE_ITEM_FAILURE:
      return action.error
    default:
      return null
  }
}

function itemLoadingReducer (action) {
  switch (action.type) {
    case ITEM.FETCH_ITEMS_REQUEST:
      return true;
    default:
      return false;
  }
}

function itemReducer (state, action) {
  switch (action.type) {
    case ITEM.FETCH_ITEMS_REQUEST:
    case ITEM.FETCH_ITEMS_FAILURE:
    case ITEM.FETCH_ITEMS_SUCCESS:
    case ITEM.CREATE_ITEM_REQUEST:
    case ITEM.CREATE_ITEM_FAILURE:
    case ITEM.CREATE_ITEM_SUCCESS:
    case ITEM.UPDATE_ITEM_REQUEST:
    case ITEM.UPDATE_ITEM_FAILURE:
    case ITEM.UPDATE_ITEM_SUCCESS:
      return {
        data: itemDataReducer(state.data, action),
        error: itemErrorReducer(action),
        loading: itemLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for item reducer.');
  };
}

ItemProvider.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.object.isRequired,
  orgRole: PropTypes.number,
  parentId: PropTypes.string.isRequired
};

export default function ItemProvider ({ feature, orgRole, parentId, children }) {
  const { user } = useSessionState();
  const { state, dispatch } = useProvider(itemReducer, ItemStateContext, ItemDispatchContext);

  useEffect(() => {
    loadItems(feature, user.uid, orgRole, parentId, dispatch);
  }, [feature, user, orgRole, parentId]);

  return (
    <ItemStateContext.Provider value={state}>
      <ItemDispatchContext.Provider value={dispatch}>
        {children}
      </ItemDispatchContext.Provider>
    </ItemStateContext.Provider>
  );
}

export function useItem () {
  return useProviderData(ItemStateContext, ItemDispatchContext);
}
