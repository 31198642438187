import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

import { db } from 'config';
import { useProvider, useProviderData } from 'hooks/useProvider';

const FavoriteStateContext = React.createContext();
const FavoriteDispatchContext = React.createContext();

function getFavoriteIds (favorites) {
  return favorites.map(favorite => favorite.id);
}

function getFavoriteRef (featureId, parentId) {
  return db.collection('favorites')
    .where('parentId', '==', parentId)
    .where('featureId', '==', featureId).get()
}

export function fetchFavorites (featureId, parentId, dispatch) {
  dispatch({ type: FAVORITE.FETCH_FAVORITES_REQUEST });
    getFavoriteRef(featureId, parentId)
      .then(query => {
        const favorites = getFavoriteIds(query.docs)
        dispatch({ type: FAVORITE.FETCH_FAVORITES_SUCCESS, favorites });
      })
      .catch(error => dispatch({ type: FAVORITE.FETCH_FAVORITES_FAILURE, error }))
}

export function deleteFavorite (itemId, dispatch) {
  dispatch({ type: FAVORITE.DELETE_FAVORITE_REQUEST });

  return db.collection('favorites').doc(itemId)
    .delete()
    .then(() => dispatch({ type: FAVORITE.DELETE_FAVORITE_SUCCESS, itemId }))
    .catch(error => dispatch({ type: FAVORITE.DELETE_FAVORITE_FAILURE, error }))
}

export function updateFavorite (featureId, parentId, itemId, dispatch) {
  const batch = db.batch();
  dispatch({ type: FAVORITE.UPDATE_FAVORITE_REQUEST });

  getFavoriteRef(featureId, parentId)
    .then(query => {
      query.forEach(doc => batch.delete(doc.ref));
      const newRef = db.collection('favorites').doc(itemId);
      batch.set(newRef, { parentId, featureId })

      return batch.commit();
    })
    .then(() => dispatch({ type: FAVORITE.UPDATE_FAVORITE_SUCCESS, itemId }))
    .catch(error => dispatch({ type: FAVORITE.UPDATE_FAVORITE_FAILURE, error }));
}

const FAVORITE = {
  FETCH_FAVORITES_REQUEST: 'FETCH_FAVORITES_REQUEST',
  FETCH_FAVORITES_FAILURE: 'FETCH_FAVORITES_FAILURE',
  FETCH_FAVORITES_SUCCESS: 'FETCH_FAVORITES_SUCCESS',
  UPDATE_FAVORITE_REQUEST: 'UPDATE_FAVORITE_REQUEST',
  UPDATE_FAVORITE_FAILURE: 'UPDATE_FAVORITE_FAILURE',
  UPDATE_FAVORITE_SUCCESS: 'UPDATE_FAVORITE_SUCCESS',
  DELETE_FAVORITE_REQUEST: 'UPDATE_FAVORITE_REQUEST',
  DELETE_FAVORITE_FAILURE: 'UPDATE_FAVORITE_FAILURE',
  DELETE_FAVORITE_SUCCESS: 'UPDATE_FAVORITE_SUCCESS'
};

function favoriteDataReducer (state, action) {
  switch (action.type) {
    case FAVORITE.FETCH_FAVORITES_SUCCESS:
      return action.favorites;
    case FAVORITE.UPDATE_FAVORITE_SUCCESS:
      return [action.itemId];
    case FAVORITE.DELETE_FAVORITE_SUCCESS:
      return [];
    default:
      return state;
  }
}

function favoriteErrorReducer (action) {
  switch (action.type) {
    case FAVORITE.FETCH_FAVORITES_FAILURE:
    case FAVORITE.UPDATE_FAVORITE_FAILURE:
    case FAVORITE.DELETE_FAVORITE_FAILURE:
      console.log(action.error);
      return action.error
    default:
      return null
  }
}

function favoriteLoadingReducer (action) {
  switch (action.type) {
    case FAVORITE.FETCH_FAVORITES_REQUEST:
      return true;
    default:
      return false;
  }
}

function favoriteReducer (state, action) {
  switch (action.type) {
    case FAVORITE.FETCH_FAVORITES_REQUEST:
    case FAVORITE.FETCH_FAVORITES_FAILURE:
    case FAVORITE.FETCH_FAVORITES_SUCCESS:
    case FAVORITE.UPDATE_FAVORITE_REQUEST:
    case FAVORITE.UPDATE_FAVORITE_FAILURE:
    case FAVORITE.UPDATE_FAVORITE_SUCCESS:
    case FAVORITE.DELETE_FAVORITE_REQUEST:
    case FAVORITE.DELETE_FAVORITE_FAILURE:
    case FAVORITE.DELETE_FAVORITE_SUCCESS:
      return {
        data: favoriteDataReducer(state.data, action),
        error: favoriteErrorReducer(action),
        loading: favoriteLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for favorite reducer.');
  };
}

FavoriteProvider.propTypes = {
  children: PropTypes.node,
  featureId: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired
};

export default function FavoriteProvider ({ featureId, parentId, children }) {
  const { state, dispatch } = useProvider(favoriteReducer, FavoriteStateContext, FavoriteDispatchContext);

  useEffect(() => {
    fetchFavorites(featureId, parentId, dispatch);
  }, [featureId, parentId]);

  return (
    <FavoriteStateContext.Provider value={state}>
      <FavoriteDispatchContext.Provider value={dispatch}>
        {children}
      </FavoriteDispatchContext.Provider>
    </FavoriteStateContext.Provider>
  );
}

export function useFavorite () {
  return useProviderData(FavoriteStateContext, FavoriteDispatchContext);
}
