import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  popper: {
    marginTop: 15,
    zIndex: theme.zIndex.drawer + 2
  },

  button: {
    padding: 0
  },

  arrow: {
    position: 'absolute',
    top: 0,
    right: 80,
    marginTop: '-0.9em',
    fontSize: 7,
    width: '3em',
    height: '1em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
});

const user = theme => ({
  root: {
    borderRadius: '50%',
    width: 60,
    height: 60,
    overflow: 'hidden',
    marginRight: 10
  }
})

export const styleUser = withStyles(user)
export default withStyles(styles);
