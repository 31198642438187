import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';


export default withStyles((theme) => ({
  root: {
    backgroundImage: 'linear-gradient(90deg, #F9F9FA 0%, #F9F9FA 1%, #CECEE1 5%, #CECEE1 96%, #CECEE1 99%, #F9F9FA 100%)',
    backgroundSize: '100% 1px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
  },
  head: {
    backgroundImage: 'none'
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: 'none',
      backgroundColor: 'transparent !important',
      borderRadius: 15,
      boxShadow: '0 9px 14px 0 rgba(0,0,0,0.14)',
      '& + tr': {
        backgroundImage: 'none'
      },
      '& td': {
        backgroundColor: '#ffffff',
      },
      '& td:first-child': {
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,

      },
      '& td:last-child': {
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15
      }
    }
  }
}))(TableRow);
