import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import useForm from 'hooks/useForm';
import style from './loginForm.styles';

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
  classes: PropTypes.object.isRequired
};

const validators = {
  email: {
    fn: (val) => (val.length),
    message: 'An Email is Required'
  },
  password: {
    fn: (val) => (val.length),
    message: 'A Password is Required'
  }
};

const defaults = {
  email: '',
  password: ''
};

function LoginForm ({ onSubmit, onError, classes }) {
  const [handleChange, handleSubmit, errors] = useForm(
    defaults,
    validators,
    onSubmit
  );

  useEffect(() => {
    if(Object.keys(errors).length) {
      onError(errors);
    }
  }, [errors, onError]);


  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <FormControl margin="normal" fullWidth error={!!errors.email}>
        <InputLabel htmlFor="email">Email Address</InputLabel>
        <Input onChange={handleChange} id="email" name="email" autoComplete="email" autoFocus />
        {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
      </FormControl>

      <FormControl margin="normal" fullWidth error={!!errors.password}>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input onChange={handleChange} name="password" type="password" id="password" autoComplete="current-password" />
        {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
      </FormControl>

      <Button type="submit" fullWidth color="primary" className={classes.submit}>
        Sign in
      </Button>
    </form>
  );
}

export default style(LoginForm);
