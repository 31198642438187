import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';


export default withStyles((theme) => ({
  root: {
    borderBottom: 'none',
    fontSize: 18,
    letterSpacing: 1,
  },
  head: {
    color: '#787B87',
    textTransform: 'uppercase'
  },
  body: {
    fontSize: 22,
    color: '#19191D',
    letterSpacing: 0,
    padding: '35px 20px'
  }
}))(TableCell);
