import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useProvider, useProviderData } from 'hooks/useProvider';
import { auth, fetch } from 'config';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const USER = {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE'
};

function userDataReducer (state, action) {
  switch (action.type) {
    case USER.FETCH_USERS_SUCCESS:
      return action.users;
    case USER.UPDATE_USER_SUCCESS:
      return state.map(user => (user.uid !== action.user.uid) ? user : action.user);
    case USER.CREATE_USER_SUCCESS:
      return state.concat([action.user]);
    default:
      return state;
  }
}

function userErrorReducer (action) {
  switch (action.type) {
    case USER.FETCH_USERS_FAILURE:
    case USER.UPDATE_USER_FAILURE:
    case USER.CREATE_USER_FAILURE:
      return action.error
    default:
      return null;
  }
}

function userLoadingReducer (action) {
  switch (action.type) {
    case USER.FETCH_USERS_REQUEST:
    case USER.UPDATE_USER_REQUEST:
    case USER.CREATE_USER_REQUEST:
      return { type: action.type };
    default:
      return null;
  }
}

function userReducer (state, action) {
  switch (action.type) {
    case USER.FETCH_USERS_REQUEST:
    case USER.FETCH_USERS_SUCCESS:
    case USER.FETCH_USERS_FAILURE:
    case USER.UPDATE_USER_REQUEST:
    case USER.UPDATE_USER_SUCCESS:
    case USER.UPDATE_USER_FAILURE:
    case USER.CREATE_USER_REQUEST:
    case USER.CREATE_USER_SUCCESS:
    case USER.CREATE_USER_FAILURE:
      return {
        ...state,
        data: userDataReducer(state.data, action),
        error: userErrorReducer(action),
        loading: userLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for admin user reducer.');
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default function UserProvider ({ children }) {
  const { state, dispatch } = useProvider(userReducer, UserStateContext, UserDispatchContext);

  useEffect(() => {
    dispatch({ type: USER.FETCH_USERS_REQUEST });
    auth.currentUser.getIdToken(true).then(() => {
      fetch.get('/admin/users')
        .then(({ data }) => dispatch({ type: USER.FETCH_USERS_SUCCESS, users: data.users}))
        .catch(error => dispatch({ type: USER.FETCH_USERS_FAILURE, error }))
    });
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

export function createUser (user, dispatch) {
  return fetch.post('/admin/users', { user })
    .then((response) => {
      dispatch({ type: USER.CREATE_USER_SUCCESS, user: response.data.user })
    })
    .catch(error => dispatch({ type: USER.CREATE_USER_FAILURE, error }))
}

export function promoteUser (user, dispatch) {
  const { uid, customClaims } = user;
  const newClaims = { ...customClaims, admin: true };
  const promotedUser = { ...user, customClaims: newClaims };

  return fetch.post(`/admin/users/${uid}/privledged`)
    .then(() => dispatch({ type: USER.UPDATE_USER_SUCCESS, user: promotedUser }))
    .catch(error => dispatch({ type: USER.UPDATE_USER_FAILURE, error }));
}

export function useUser () {
  return useProviderData(UserStateContext, UserDispatchContext);
}
