import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useForm from 'hooks/useForm';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './userForm.styles';

UserInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};

function UserInput ({name, label, className, fullWidth, value, error, ...rest}) {
  return (
    <FormControl fullWidth={fullWidth} className={className} margin="normal" error={!!error} aria-describedby="{name}-error-text">
      <InputLabel htmlFor="input-{name}">{label}</InputLabel>
      <Input id="input-{name}" name={name} value={value} {...rest} />
      {!!error && <FormHelperText id="{name}-error-text">{error}</FormHelperText>}
    </FormControl>
  );
};


const validators = {
  ownerName: {
    fn: (val) => (val.length),
    message: 'Owner name is Required'
  },
  ownerEmail: {
    fn: (val) => (val.length),
    message: 'Owner email is Required'
  },
  ownerPassword: {
    fn: (val) => (val.length),
    message: 'Owner password is Required'
  }
}

const defaults = {
  ownerName: '',
  ownerEmail: '',
  ownerPassword: ''
};

OrgForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  org: PropTypes.object
};

function OrgForm ({
  classes,
  org,
  onSubmit
}) {
  const [handleChange, handleSubmit, errors, values] = useForm(
    { ...defaults, ...org },
    validators,
    onSubmit
  );

  return (
    <div className={classes.formWrapper}>
      <Paper className={classes.paper}>
        <div className={classes.row}>
          <UserInput
            fullWidth
            name="ownerName"
            label="Owner Name"
            value={values.ownerName}
            error={errors.ownerName}
            onChange={handleChange}/>
        </div>

        <div className={classes.row}>
          <UserInput
            className={classes.textFieldLong}
            name="ownerEmail"
            label="Owner Email"
            value={values.ownerEmail}
            error={errors.ownerEmail}
            onChange={handleChange} />

          <UserInput
            className={classes.textFieldLong}
            name="ownerPassword"
            label="Owner Password"
            value={values.ownerPassword}
            error={errors.ownerPassword}
            onChange={handleChange} />
        </div>

        <div className={classes.buttonRow}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className={classes.button}>
              Create User
          </Button>
        </div>
      </Paper>
    </div>
  );
};


export default styles(OrgForm);
