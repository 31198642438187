import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import FeatureProvider, { useFeature } from '../../providers/Feature';
import OrgReadView from './OrgRead.view';

function OrgReadRoutes ({ org }) {
  const { pristine, loading, error, data: features } = useFeature();
  if (pristine || loading) return <Loading message="Loading Bands" />
  if (error) return <div>We could not load organization features</div>;
  return <OrgReadView org={org} features={features} />
}

function OrgReadScreen ({ org, classes }) {
  return (
    <FeatureProvider plan={org.plan}>
      <OrgReadRoutes org={org} />
    </FeatureProvider>
  )
}

export default OrgReadScreen;
