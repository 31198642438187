import React from 'react';
import { Route, useRouteMatch, useParams } from 'react-router-dom';

import ValidItem from 'components/ValidItem';
import MemberList from './screens/MemberList';
import MemberRead from './screens/MemberRead';

function withMember (Component, member) {
  return function (props) {
    return <Component member={member} {...props} />
  }
}

function FindMember ({ members, redirect, children }) {
  const { memberId } = useParams();
  return (
    <ValidItem collection={members} itemId={memberId} redirect={redirect}>
      { member => children(member) }
    </ValidItem>
  )
}

export default function MemberView ({ members, org }) {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <MemberList members={members} org={org} />
      </Route>

      <Route path={`${path}/:memberId`}>
        <FindMember members={members} redirect={path}>
          { member => <MemberRead org={org} member={member} /> }
        </FindMember>
      </Route>
    </>
  )
};
