import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import { useSessionState } from 'providers/Session';
import { useOrg } from 'providers/Org';
import MembersOrgsProvider from 'providers/MembersOrgs';
import OrgsView from './Orgs.view'
import Loading from 'components/Loading';

import useStyles from './orgs.styles';

function OrgRoutes () {
  const { path } = useRouteMatch();
  const { pristine, loading, error, data: orgs } = useOrg();

  if (pristine || loading) return <Loading message="Loading Your Labels" />
  if (error) return <div>We could not load any organizations</div>;
  return <Route path={path}><OrgsView orgs={orgs} /></Route>
}

export default function OrgsScreen () {
  const classes = useStyles();
  const { user } = useSessionState();

  return (
    <MembersOrgsProvider uid={user.uid}>
      <div className={classes.root}>
        <OrgRoutes />
      </div>
    </MembersOrgsProvider>
  );
}
