import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import styles from './memberTable.styles';

MemberRow.propTypes = {
  member: PropTypes.object.isRequired
};

function MemberRow ({ member, classes }) {
  const { url } = useRouteMatch();

  return (
    <TableRow hover>
      <TableCell><Link to={`${url}/${member.id}`} className={classes.rowLink}>{member.name}</Link></TableCell>
    </TableRow>
  );
}

function MemberBody ({ members, classes }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map(member => <MemberRow key={member.id} member={member} classes={classes} /> )}
      </TableBody>
    </Table>
  )
}

MemberTable.propTypes = {
  members: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

function MemberTable ({ members, classes }) {
  return (members.length)
    ? <MemberBody members={members} classes={classes} />
    : <div className={classes.contentWrapper}>
        <Typography color="textSecondary" align="center">No members for this organization</Typography>
      </div>
}

export default styles(MemberTable);
