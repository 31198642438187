import React from 'react';
import PropTypes from 'prop-types';
import { Route, useRouteMatch } from 'react-router-dom';

import MemberProvider, { useMemberState } from 'providers/Member';
import MemberView from './Member.view';

function MemberRoute ({ org }) {
  const { data: members } = useMemberState();
  const { path, url } = useRouteMatch();

  switch (members) {
    case undefined:
      return <div>Loading organization members</div>;
    case null:
      return <div>We could not load any members</div>;
    default:
      return <Route path={path}><MemberView org={org} members={members} /></Route>
  }
}

export default function MemberScreen ({ org }) {
  return (
    <MemberProvider org={org}>
      <MemberRoute org={org} />
    </MemberProvider>
  )
}
