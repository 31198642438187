
import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import style from './loginCard.styles';

LoginCard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired
};

function LoginCard ({ children, classes }) {
  return (
    <Paper className={classes.paper}>
      <header className={classes.header}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>

        <Typography variant="h5">Sign In</Typography>
      </header>

      {children}

    </Paper>
  );
}


export default style(LoginCard);
