import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { db } from 'config';

const AssignmentStateContext = React.createContext();
const AssignmentDispatchContext = React.createContext();

function loadAssignments (itemId, collection, dispatch) {
  dispatch({ type: ASSIGNMENT.FETCH_ASSIGNMENTS_REQUEST });
  return db.collection(`${collection}_members`).doc(itemId)
    .collection('members').get().then(query => (
      query.docs.map(doc => doc.id)
    )).then(assignments => dispatch({ type: ASSIGNMENT.FETCH_ASSIGNMENTS_SUCCESS, assignments }))
      .catch(error => dispatch({ type: ASSIGNMENT.FETCH_ASSIGNMENTS_FAILURE, error }));
}

function getDeleteAssignmentBatch (collectionName, itemId) {
  const batch = db.batch();

  const membersRef = db.collection(`${collectionName}_members/${itemId}/members`)
  return membersRef.get()
    .then(query => {
      query.docs.forEach(memberAssignment => {
        const collectionAssignmentRef = db.doc(`members_${collectionName}/${memberAssignment.id}/${collectionName}/${itemId}`);
        batch.delete(collectionAssignmentRef);
        batch.delete(memberAssignment.ref);
      });

      return { membersRef, batch };
    })
}

export function setAssignments (collectionName, itemId, orgId, assignments, dispatch) {
  dispatch({ type: ASSIGNMENT.CREATE_ASSIGNMENT_REQUEST });

  getDeleteAssignmentBatch(collectionName, itemId)
    .then(({ membersRef, batch }) => {
      assignments.forEach(assignment => {
        batch.set(membersRef.doc(assignment), { itemRole: 50 })
        batch.set(db.doc(`members_${collectionName}/${assignment}/${collectionName}/${itemId}`), { parentId: orgId });
      })

      return batch.commit();
    })
   .then(() => dispatch({ type: ASSIGNMENT.CREATE_ASSIGNMENT_SUCCESS, assignments }))
   .catch(error => {
      dispatch({ type: ASSIGNMENT.CREATE_ASSIGNMENT_FAILURE, error });
    })
}

export function deleteAllAssignments(collectionName, itemId) {
  getDeleteAssignmentBatch (collectionName, itemId)
    .then(({ batch }) => batch.commit());
}

const ASSIGNMENT = {
  FETCH_ASSIGNMENTS_REQUEST: 'FETCH_ASSIGNMENTS_REQUEST',
  FETCH_ASSIGNMENTS_FAILURE: 'FETCH_ASSIGNMENTS_FAILURE',
  FETCH_ASSIGNMENTS_SUCCESS: 'FETCH_ASSIGNMENTS_SUCCESS',
  CREATE_ASSIGNMENT_REQUEST: 'CREATE_ASSIGNMENT_REQUEST',
  CREATE_ASSIGNMENT_FAILURE: 'CREATE_ASSIGNMENT_FAILURE',
  CREATE_ASSIGNMENT_SUCCESS: 'CREATE_ASSIGNMENT_SUCCESS'
};

function assignmentDataReducer (state, action) {
  switch (action.type) {
    case ASSIGNMENT.FETCH_ASSIGNMENTS_REQUEST:
      return undefined;
    case ASSIGNMENT.FETCH_ASSIGNMENTS_FAILURE:
    case ASSIGNMENT.CREATE_ASSIGNMENT_FAILURE:
      console.log(action.error);
      return null;
    case ASSIGNMENT.FETCH_ASSIGNMENTS_SUCCESS:
    case ASSIGNMENT.CREATE_ASSIGNMENT_SUCCESS:
      return action.assignments;
    default:
      return state;
  }
}

function assignmentReducer (state, action) {
  switch (action.type) {
    case ASSIGNMENT.FETCH_ASSIGNMENTS_REQUEST:
    case ASSIGNMENT.FETCH_ASSIGNMENTS_FAILURE:
    case ASSIGNMENT.FETCH_ASSIGNMENTS_SUCCESS:
    case ASSIGNMENT.CREATE_ASSIGNMENT_REQUEST:
    case ASSIGNMENT.CREATE_ASSIGNMENT_FAILURE:
    case ASSIGNMENT.CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        data: assignmentDataReducer(state.data, action)
      }
    default:
      throw Error('Unknown action type for assignment reducer.');
  };
}

AssignmentProvider.propTypes = {
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired,
  feature: PropTypes.object.isRequired
};

export default function AssignmentProvider ({ itemId, feature, children }) {
  const [assignment, dispatch] = useReducer(assignmentReducer, {
    data: undefined
  });

  useEffect(() => {
    loadAssignments(itemId, feature.collection, dispatch);
  }, [itemId, feature]);

  return (
    <AssignmentStateContext.Provider value={assignment}>
      <AssignmentDispatchContext.Provider value={dispatch}>
        {children}
      </AssignmentDispatchContext.Provider>
    </AssignmentStateContext.Provider>
  );
}

export function useAssignmentState () {
  return useContext(AssignmentStateContext);
}

export function useAssignmentDispatch () {
  return useContext(AssignmentDispatchContext);
}
