import { withStyles } from '@material-ui/core/styles';
const drawerWidth = 286;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  paper: {
    width: drawerWidth,
    borderRight: 'none',
    backgroundImage: 'linear-gradient(180deg, #393B47 0%, #13171A 100%)'
  },
  docked: {
  },
  toolBar: {
    minHeight: 100
  },
  list: {
    marginTop: 50
  },
  item: {
    borderRadius: '10px 0 0 10px',
    marginBottom: 30,
    padding: '8px 0 12px 30px',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: '#292B37'
    },
    '& .start-stop, & .end-stop': {
      stopColor: '#787B87'
    },
    '&.active': {
      backgroundColor: '#1C1E29',
      '& $itemPrimary': {
        backgroundClip: 'text',
        '-webkitBackgroundClip': 'text',
        textFillColor: 'transparent',
        '-webkitTextFillColor': 'transparent',
        backgroundImage: 'linear-gradient(180deg, #D37CFF 0%, #FD46E5 100%)'
      },
      '& .start-stop': {
        stopColor: '#D37CFF'
      },
      '& .end-stop': {
        stopColor: '#FD46E5'
      }
    }
  },
  itemPrimary: {
    color: '#777B89',
    fontSize: '22px',
  },
  itemIcon: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '34px',
    marginRight: theme.spacing(2),
  }
});

export default withStyles(styles);
