import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Gravatar from 'react-gravatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import { auth } from 'config';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import styles, { styleUser } from './userMenu.styles';

const MenuHeader = ({user}) => (
  <ListSubheader component="div">{ user.email }</ListSubheader>
);

const UserIcon = ({ user, classes }) => (
  <div className={classes.root}><Gravatar email={user.email} size={60} rating="pg" default="mp" /></div>
)

const StyledUserIcon = styleUser(UserIcon);

MenuHeader.propTypes = {
  user: PropTypes.object.isRequired
};

UserMenu.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

function UserMenu ({ user, classes }) {
  const [anchor, setAnchor] = useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  function signOut () {
    auth.signOut();
  }

  function handleAccountClick (e) {
    const anchor = e.currentTarget;
    setAnchor(anchor);
  }

  function handleMenuClose () {
    setAnchor(null);
  }

  const open = !!anchor;

  return (
    <>
      <IconButton className={classes.button} color="inherit" onClick={handleAccountClick}>
        <StyledUserIcon user={user} />
        <ArrowIcon />
      </IconButton>

      <Popper open={open} placement="bottom" anchorEl={anchor} className={classes.popper}>
        <span className={classes.arrow} ref={setArrowRef} />
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>

            <MenuList subheader={<MenuHeader user={user}/>}>
              <Divider />
              <MenuItem onClick={signOut}>Logout</MenuItem>
            </MenuList>

          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}

export default styles(UserMenu);
