import React, { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { auth, fetch } from 'config';

const SessionStateContext = React.createContext();
const SessionDispatchContext = React.createContext();

SessionProvider.propTypes = {
  children: PropTypes.node
};

const USER = {
  AUTH_CHANGE_LOGIN: 'AUTH_CHANGE_LOGIN',
  AUTH_CHANGE_LOGOUT: 'AUTH_CHANGE_LOGOUT'
};

function sessionUserReducer (action) {
  switch (action.type) {
    case USER.AUTH_CHANGE_LOGIN:
      return action.record;
    default:
      return null;
  }
};

function sessionReducer (state, action) {
  switch (action.type) {
    case USER.AUTH_CHANGE_LOGIN:
    case USER.AUTH_CHANGE_LOGOUT:
      return {
        ...state,
        user: sessionUserReducer(action)
      };
    default:
      throw Error('Unknown action type for sessionReducer.');
  }
}

export default function SessionProvider ({ children }) {
  const [session, dispatch] = useReducer(sessionReducer, {
    user: undefined
  });

  useEffect(() => {
    auth.onAuthStateChanged(record => {
      (record && record.uid)
        ? createUserSession(record, dispatch)
        : dispatch({ type: USER.AUTH_CHANGE_LOGOUT });
    });
  }, []);

  return (
    <SessionStateContext.Provider value={session}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  );
}

function createUserSession (record, dispatch) {
  /* We have both direct firebase communication and
     also communication through a rest api, both must
     be configured to allow requests */

  record.getIdToken().then(data => {
    fetch.defaults.headers.common['Authorization'] = 'Bearer ' + data;
    fetch.defaults.headers.common['Content-Type'] = 'application/json';
    dispatch({ type: USER.AUTH_CHANGE_LOGIN, record });
  })

}

export function useSessionState () {
  return useContext(SessionStateContext);
}

export function useSessionDispatch () {
  return useContext(SessionDispatchContext);
}

