import { makeStyles } from '@material-ui/core/styles';

const styledBy = (property, mapping) => (props) => mapping[props[property]];
const styles = theme => ({
  root: {
    width: 1,
    height: (props) => props.stretch ? '100%' : 40,
    backgroundImage: styledBy('variant', {
      dark: 'linear-gradient(180deg, #262733 0%, #4F5362 18%, #4F5362 81%, #262734 100%)',
      light: 'linear-gradient(180deg, #F9F9FA 0%, #CECEE1 18%, #CECEE1 81%, #F9F9FA 100%)'
    })
  }
});

export default makeStyles(styles);
