import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import TableHeader from 'components/TableHeader';
import OrgTable from './components/OrgTable';
import styles from './orgList.styles.js'

function OrgList ({ orgs, classes }) {
  return (
    <div className={classes.root}>
      <TableHeader title="Your Record Labels" />
      <OrgTable orgs={orgs} />
    </div>
  )
}

export default styles(OrgList);
