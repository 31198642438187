import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { useFeature } from '../../providers/Feature';
import FeaturePathProvider from 'providers/FeaturePath';
import UserHeader from 'components/UserHeader';
import OrgNav from './components/OrgNav';
import Feature from 'features';
import Member from './screens/Member';

import styles from './orgRead.styles';

function RedirectToFeature ({ features }) {
  const { url } = useRouteMatch();

  return (features)
    ? <Redirect to={`${url}/${features[0].slug}`} />
    : null
}

function OrgReadView ({ org, features, classes }) {
  const { path, url } = useRouteMatch();
  return (
    <div className={classes.root}>
      <OrgNav orgUrl={url} org={org} features={features} />
      <div className={classes.main}>
          <Switch>
            <Route exact path={path}><RedirectToFeature features={features} /></Route>
            <Route path={`${url}/members`}><Member org={org} /></Route>
            <Route path={`${url}/:featureSlug`} render={({match}) => {
              const { featureSlug } = match.params;

              return (
                <FeaturePathProvider>
                  <Feature
                    key={featureSlug }
                    featureSlug={featureSlug}
                    redirect={url}
                    org={org}
                    parentId={org.id}
                  />
                </FeaturePathProvider>
              )
            }} />
          </Switch>
      </div>
    </div>
  );
}

export default styles(OrgReadView);
