import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useRouteMatch, useParams, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFeature } from 'screens/Orgs/providers/Feature';
import ItemProvider, { useItem } from './providers/Item';
import FavoriteProvider from './providers/Favorite';
import AssignmentProvider from 'providers/Assignment';
import Loading from 'components/Loading';

import useStyles from './feature.styles';
/* TODO: get these from the component property on the feature */

const components = {
  Division: React.lazy(() => import('./screens/Division')),
  Profile: React.lazy(() => import('./screens/Profile')),
  Tour: React.lazy(() => import('./screens/Tour')),
  Album: React.lazy(() => import('./screens/Album')),
  Photo: React.lazy(() => import('./screens/Photo')),
  Assignment: React.lazy(() => import('./screens/Assignment')),
  Website: React.lazy(() => import('./screens/Website'))
};

function ChildFeature ({ itemId, org, feature, redirect }) {
  const classes = useStyles();
  const { featureSlug } = useParams();

  /* We need to completely dismount the feature component
     because we are using the same provider. You'll end up
     with the old items existing for a split second otherwise */

    return (
      <div className={classes.root}>
        <Feature
          key={featureSlug}
          featureSlug={featureSlug}
          redirect={redirect}
          org={org}
          parentId={itemId}
          parentFeature={feature}
        />
      </div>
    )
}

function ChildFeatureRoutes ({ features, feature, org }) {
  const { itemId } = useParams();
  const { url } = useRouteMatch();

  function AnotherFeatureRoute () {
    return (
      <Route path={`${url}/:featureSlug`}>
        <ChildFeature itemId={itemId} org={org} redirect={url} feature={feature} />
      </Route>
    )
  }

  return (feature.permissions == 'assigned')
    ?  <AssignmentProvider itemId={itemId} feature={feature}>
        <AnotherFeatureRoute />
      </AssignmentProvider>
    : <AnotherFeatureRoute />

}

function FeatureDetailRoutes (props) {
  const { itemId: parentId } = useParams();
  const { features, feature, org } = props;

  return (
    <ChildFeatureRoutes features={features} org={org} feature={feature} />
  );
}

function FeatureRoutes (props) {
  const { url } = useRouteMatch();
  const { feature, features, org } = props;
  const Component = components[feature.component];
  return (
    <>
      <Route path={url}>
        <Suspense fallback={<div>Loading {feature.name}  UI</div>}>
          <Component {...props} />
          <Route path={`${url}/:itemId`}>
            <FeatureDetailRoutes {...props} />
          </Route>
        </Suspense>
      </Route>
    </>
  );
}

function FeatureFavorites (props) {
  const { feature, parentId } = props;
  return <FavoriteProvider featureId={feature.id} parentId={parentId}>
    <FeatureRoutes {...props} />
  </FavoriteProvider>
}

function FeatureScreen (props) {
  const { feature } = props;
  const { pristine, loading, error, data } = useItem();
  if (pristine || loading) return <Loading message={`Loading ${feature.name}`} />;
  if (error) return <div>We could not load {feature.name}</div>;
  return (feature.favorites)
    ? <FeatureFavorites {...props} items={data} />
    : <FeatureRoutes {...props} items={data} />;
}

function ValidFeature (props) {
  const { org, parentId, features, featureSlug, redirect } = props;
  const feature = features.find(f => f.slug === featureSlug);
  if (!feature) return <Redirect to={`${redirect}/${features[0].slug}`} />;

  return (!feature.collection)
    ? <FeatureRoutes feature={feature} {...props} />
    : <ItemProvider feature={feature} orgRole={org.orgRole} parentId={parentId}>
        <FeatureScreen feature={feature} {...props} />
      </ItemProvider>
}

export default function Feature (props) {
  const { data: features } = useFeature();
  return <ValidFeature features={features} {...props} />;
}


