import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  }
});

export default makeStyles(styles);
