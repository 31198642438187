import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  formWrapper: {
    margin: theme.spacing(0, -1)
  },

  paper: {
    padding: theme.spacing(2)
  },

  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },

  textFieldLong: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flex: '1 1 48%',
      maxWidth: '48%'
    }
  },

  textField: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flex: '1 1 31%',
      maxWidth: '31%'
    }
  },

  buttonRow: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },

  button: {
    marginLeft: theme.spacing(2)
  }
});

export default withStyles(styles);
