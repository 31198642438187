import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

import { useProvider, useProviderData } from 'hooks/useProvider';

const FeaturePathStateContext = React.createContext();
const FeaturePathDispatchContext = React.createContext();

export function createFeatureSegment (feature, dispatch) {
  dispatch({ type: FEATUREPATH.CREATE_FEATURE_SEGMENT, feature });
}

export function deleteFeatureSegment (dispatch) {
  dispatch({ type: FEATUREPATH.DELETE_FEATURE_SEGMENT });
}

const FEATUREPATH = {
  CREATE_FEATURE_SEGMENT: 'CREATE_FEATURE_SEGMENT',
  DELETE_FEATURE_SEGMENT: 'DELETE_FEATURE_SEGMENT'
}

function featurePathDataReducer (state, action) {
  switch (action.type) {
    case FEATUREPATH.CREATE_FEATURE_SEGMENT:
      return state.concat([action.feature]);
    case FEATUREPATH.DELETE_FEATURE_SEGMENT:
      return state.slice(0, -1);
    default:
      return state;
  }
}

function featurePathReducer (state, action) {
  switch (action.type) {
    case FEATUREPATH.CREATE_FEATURE_SEGMENT:
    case FEATUREPATH.DELETE_FEATURE_SEGMENT:
      return { ...state,
        data: featurePathDataReducer(state.data, action)
      }
    default:
      throw new Error('Unknown action type for feature path provider')
  }
}

export default function FeaturePathProvider ({ children }) {
  const { state, dispatch } = useProvider(featurePathReducer, FeaturePathStateContext, FeaturePathDispatchContext);

  return (
    <FeaturePathStateContext.Provider value={state}>
      <FeaturePathDispatchContext.Provider value={dispatch}>
        {children}
      </FeaturePathDispatchContext.Provider>
    </FeaturePathStateContext.Provider>
  );
}

export function useFeaturePath () {
  return useProviderData(FeaturePathStateContext, FeaturePathDispatchContext);
}
