import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

import MemberTable from './components/MemberTable';
import MemberCreate from './components/MemberCreate';
import { createMember, useMemberDispatch } from 'providers/Member';
import styles from './memberList.styles.js'

function MemberList ({ members, org, classes }) {
  const [open, setOpen] = useState(false);
  const dispatch = useMemberDispatch();
  const { id, orgRole } = org;

  function showForm () {
    setOpen(true);
  }

  function hideForm () {
    setOpen(false);
  }

  function handleCreateMember (member) {
    createMember(member, id, dispatch);
    hideForm();
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6">Members</Typography>
              </Grid>

              {orgRole >= 100 && <Grid item>
                <Button variant="contained" color="primary" className={classes.addDivision} onClick={showForm}>Add member</Button>
              </Grid>}
            </Grid>
          </Toolbar>
        </AppBar>
        {open && <MemberCreate onFormCancel={hideForm} onFormSubmit={handleCreateMember} />}
        <MemberTable members={members} />
      </Paper>
    </div>
  )
}

export default styles(MemberList);
