import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginBottom: 20
  },
  tableHeading: {
    fontSize: 35,
    color: '#19191D',
    letterSpacing: 0.5
  },
  action: {
    fontSize: 18,
    fontWeight: 700,
    color: '#787B87',
    letterSpacing: 1,
    textTransform: 'uppercase'
  },
  actionIcon: {
    marginRight: 10
  },
  searchIcon: {
    marginTop: 5
  },
  divider: {
    margin: '0 10px'
  },
  addNew: {
    backgroundImage: 'linear-gradient(180deg, #D37CFF 0%, #FD46E5 100%)',
    borderRadius: 10,
    padding: 10,
    minWidth: 0
  }
});

export default makeStyles(styles);
