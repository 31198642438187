import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
});

export default makeStyles(styles);
