import React from 'react';
import { Route } from 'react-router-dom';

import SessionProvider from 'providers/Session';
import PrivateRoute from 'components/PrivateRoute';
import UserHeader from 'components/UserHeader';

import Home from 'screens/Home';
import Login from 'screens/Login';
import Admin from 'screens/Admin';
import Orgs from 'screens/Orgs';

import useStyles from './app.styles'

export default function App() {
  const classes = useStyles();

  return (
    <SessionProvider>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} data-testid="login-screen"/>
      <PrivateRoute path="/orgs">
        {(props, session) => (
          <div className={classes.root}>
            <UserHeader />
            <Orgs data-testid="orgs-screen"/>
          </div>
        )}
      </PrivateRoute>

      <PrivateRoute path="/admin">
        {(props, session) => (
          <Admin user={session.user} />
        )}
      </PrivateRoute>
    </SessionProvider>
  );
}
