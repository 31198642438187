import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from 'config';

import useForm from 'hooks/useForm';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './orgForm.styles';

OrgInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};

function OrgSearch ({ onAssign }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    db.collection('orgs').orderBy('name').startAt(inputValue).endAt(inputValue + '~').limit(10)
      .get().then((query) => (
        query.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      )).then(results => setOptions(results));
  }, [value, inputValue])

  function handleAssign () {
    onAssign(value);
  }

  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        style={{ width: 300 }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Asynchronous"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />

      <Button onClick={handleAssign}>Assign Org</Button>
    </>
  );
}

function OrgInput ({name, label, className, fullWidth, value, error, ...rest}) {
  return (
    <FormControl fullWidth={fullWidth} className={className} margin="normal" error={!!error} aria-describedby="{name}-error-text">
      <InputLabel htmlFor="input-{name}">{label}</InputLabel>
      <Input id="input-{name}" name={name} value={value} {...rest} />
      {!!error && <FormHelperText id="{name}-error-text">{error}</FormHelperText>}
    </FormControl>
  );
};


const validators = {
  name: {
    fn: (val) => (val.length),
    message: 'Name is Required'
  }
}

const defaults = {
  name: '',
};

OrgForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  org: PropTypes.object
};

function OrgForm ({
  classes,
  org,
  onSubmit,
  onAssign
}) {
  const [handleChange, handleSubmit, errors, values] = useForm(
    { ...defaults, ...org },
    validators,
    onSubmit
  );

  return (
    <React.Fragment>
      <OrgSearch onAssign={onAssign} />
      <div className={classes.row}>
        <OrgInput
          fullWidth
          name="name"
          label="Organization Name"
          autoFocus
          value={values.name}
          error={errors.name}
          onChange={handleChange}/>
      </div>

      <div className={classes.buttonRow}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.button}>
            Create Organization
        </Button>
      </div>
    </React.Fragment>
  );
};


export default styles(OrgForm);
