import React from 'react';
import { Route, useRouteMatch, useParams } from 'react-router-dom';

import ValidItem from 'components/ValidItem';
import OrgList from './screens/OrgList';
import OrgRead from './screens/OrgRead';

function FindOrg ({ orgs, redirect }) {
  const { orgId } = useParams();

  return (
    <ValidItem collection={orgs} itemId={orgId} redirect={redirect}>
      { org => <OrgRead org={org} /> }
    </ValidItem>
  );
}

export default function OrgsView ({ orgs }) {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <OrgList orgs={orgs} />
      </Route>

      <Route path={`${path}/:orgId`}>
        <FindOrg orgs={orgs} redirect={path}/>
      </Route>
    </>
  );
}
