import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from 'components/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from 'components/TableRow';

import styles from './orgTable.styles.js'

function OrgTableRow ({ org, classes, onRowClick }) {
  function handleClick () {
    onRowClick(org.id)
  }
  return (
    <TableRow hover onClick={handleClick}>
      <TableCell>{org.name}</TableCell>
    </TableRow>
  )
}

function OrgTable ({ orgs, classes }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  function handleRowClick (orgId) {
    history.push(`${url}/${orgId}`);
  }

  return (
    (orgs.length)
      ?  <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgs.map(org => <OrgTableRow key={org.id} org={org} onRowClick={handleRowClick} />)}
          </TableBody>
        </Table>

      : <div className={classes.contentWrapper}>
          <Typography color="textSecondary" align="center">
            No users for this project yet
          </Typography>
        </div>

  );
}
export default styles(OrgTable);
