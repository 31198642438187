import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(6, 4)
  },
  paper: {
    maxWidth: 936,
    margin: 'auto'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginLeft: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  rowLink: {
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none'
  }
});

export default withStyles(styles);
