import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import axios from 'axios';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
});

export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();

const settings = (process.env.NODE_ENV !== 'production')
  ? { host: 'localhost:8080', ssl: false }
  : {};

db.settings(settings);


export const fetch = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});



