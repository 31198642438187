import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

import VertDivider from 'components/VertDivider';
import { ReactComponent as SortIcon } from './sort.svg';
import { ReactComponent as FilterIcon } from './filter.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as AddIcon } from './add.svg';

import useStyles from './tableHeader.styles';

function TableHeader ({ title, showButton, onNewClick}) {
  const classes = useStyles();

  return (
    <AppBar position="static" color="transparent" elevation={0} className={classes.root}>
      <Toolbar disableGutters={true}>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <Typography variant="h6" component="h3" className={classes.tableHeading}>{ title || 'Your Items' }</Typography>
          </Grid>

          <Grid item xs />

          <Grid item className={classes.action}>
            <SortIcon className={classes.actionIcon}/>
            Sort
          </Grid>

          <Grid item className={classes.action}>
            <FilterIcon className={classes.actionIcon} />
            Filter
          </Grid>

          <Grid item>
            <SearchIcon className={classes.searchIcon} />
          </Grid>
          {showButton &&
            <>
              <div className={classes.divider}><VertDivider variant="light"/></div>
              <Grid item>
                <Button className={classes.addNew} onClick={onNewClick}><AddIcon /></Button>
              </Grid>
            </>
          }
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default TableHeader;
