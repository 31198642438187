import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { db } from 'config';
import { useProvider, useProviderData } from 'hooks/useProvider';

const FeatureStateContext = React.createContext();
const FeatureDispatchContext = React.createContext();

function getItems (refs) {
  return refs.map(ref => ref.get())
}

function loadFeatures (plan, dispatch) {
  dispatch({ type: FEATURE.FETCH_FEATURES_REQUEST });
  plan.get().then(doc => {
    if (doc.exists) {
      return doc.data().features
    }

    throw new Error('Could not find organization plan');
  })
  .then(getItems)
  .then(promises => Promise.all(promises))
  .then(docs => docs.map(doc => {
    if (doc.exists) {
      return { id: doc.id, ...doc.data() };
    }

    throw new Error('Could not find organization features');
  }))
  .then(features => dispatch({ type: FEATURE.FETCH_FEATURES_SUCCESS, features }))
  .catch(error => dispatch({ type: FEATURE.FETCH_FEATURES_FAILURE, error }));
}

const FEATURE = {
  FETCH_FEATURES_REQUEST: 'FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_FAILURE: 'FETCH_FEATURES_FAILURE',
  FETCH_FEATURES_SUCCESS: 'FETCH_FEATURES_SUCCESS'
};

function featureDataReducer (state, action) {
  switch (action.type) {
    case FEATURE.FETCH_FEATURES_SUCCESS:
      return action.features;
    default:
      return state;
  }
}

function featureErrorReducer (action) {
  switch (action.type) {
    case FEATURE.FETCH_FEATURES_FAILURE:
      return action.error;
    default:
      return null;
  }
}

function featureLoadingReducer (action) {
  switch (action.type) {
    case FEATURE.FETCH_FEATURES_REQUEST:
      return true;
    default:
      return false;
  }
}

function featureReducer (state, action) {
  switch (action.type) {
    case FEATURE.FETCH_FEATURES_REQUEST:
    case FEATURE.FETCH_FEATURES_FAILURE:
    case FEATURE.FETCH_FEATURES_SUCCESS:
      return {
        data: featureDataReducer(state.data, action),
        error: featureErrorReducer(action),
        loading: featureLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for feature reducer.');
  };
}

FeatureProvider.propTypes = {
  plan: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default function FeatureProvider ({ plan, children }) {
  const { state, dispatch } = useProvider(featureReducer, FeatureStateContext, FeatureDispatchContext);

  useEffect(() => {
    loadFeatures(plan, dispatch);
  }, [plan]);

  return (
    <FeatureStateContext.Provider value={state}>
      <FeatureDispatchContext.Provider value={dispatch}>
        {children}
      </FeatureDispatchContext.Provider>
    </FeatureStateContext.Provider>
  );
}

export function useFeature () {
  return useProviderData(FeatureStateContext, FeatureDispatchContext)
}
