import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { useSessionState } from 'providers/Session';

PrivateRoute.propTypes = {
  children: PropTypes.func.isRequired,
}

export default function PrivateRoute ({ children, ...rest }) {
  const session = useSessionState();

  return (session.user === undefined)
    ? null
    : <Route { ...rest } render={props => (
        session.user === null
          ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          : children(props, session)
      )} />
};

