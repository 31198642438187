import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

function getStateWrapper (state) {
  return function ({ loading, error, children }) {
    if (state.pristine || state.loading) return loading;
    if (state.error) return error;
    return children;
  }
}

function getProviderComponent (state, dispatch, StateContext, DispatchContext) {
  return function ({ children }) {
    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }
}

/* TODO: it would be nice to remove the dependency on React Router */

function ValidateItemRoute ({ children, collection, notFound }) {
  const { path, params } = useRouteMatch();
  const segment = path.split('/').pop().replace(/^:/, '');
  const id = params[segment];
  const item = collection.find(i => i.id === id);

  return (item)
    ? children(item)
    : notFound || <Redirect to="/" />;
}

function getItemRoute (collection) {
  ItemRoute.propTypes = {
    children: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    notFound: PropTypes.node
  };

  function ItemRoute ({ children, path, notFound }) {
    return (
      <Route path={path}>
        <ValidateItemRoute collection={collection} notFound={notFound}>
          {children}
        </ValidateItemRoute>
      </Route>
    )
  }

  return ItemRoute;
}

export function useProvider (reducer, stateContext, dispatchContext) {
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    error: null,
    loading: false,
    pristine: true
  });

  return {
    state,
    dispatch
  }
}

export function useProviderData (stateContext, dispatchContext) {
  const state = useContext(stateContext);
  const dispatch = useContext(dispatchContext);

  return {
    ...state,
    dispatch
  }
}


