import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import useForm from 'hooks/useForm';
import styles from './memberCreate.styles';

OrgInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};

function OrgInput ({name, label, className, fullWidth, value, error, ...rest}) {
  return (
    <FormControl fullWidth={fullWidth} className={className} margin="normal" error={!!error} aria-describedby="{name}-error-text">
      <InputLabel htmlFor="input-{name}">{label}</InputLabel>
      <Input id="input-{name}" name={name} value={value} {...rest} />
      {!!error && <FormHelperText id="{name}-error-text">{error}</FormHelperText>}
    </FormControl>
  );
};


const validators = {
  name: {
    fn: (val) => (val.length),
    message: 'Name is Required'
  },
  email: {
    fn: (val) => (val.length),
    message: 'Email is Required'
  },
  password: {
    fn: (val) => (val.length),
    message: 'Password is Required'
  }
}

const defaults = {
  name: '',
  email: '',
  password: ''
};

MemberCreate.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

function MemberCreate ({
  classes,
  onFormSubmit,
  onFormCancel
}) {
  const [handleChange, handleSubmit, errors, values] = useForm(
    defaults,
    validators,
    onFormSubmit
  );

  return (
    <div className={classes.formWrapper}>
      <Paper className={classes.paper}>
        <div className={classes.row}>
          <OrgInput
            fullWidth
            name="name"
            label="Name"
            value={values.name}
            error={errors.name}
            onChange={handleChange}/>
        </div>

        <div className={classes.row}>
          <OrgInput
            className={classes.textFieldLong}
            name="email"
            label="Email"
            value={values.email}
            error={errors.email}
            onChange={handleChange} />

          <OrgInput
            className={classes.textFieldLong}
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={errors.password}
            onChange={handleChange} />
        </div>

        <div className={classes.buttonRow}>
          {onFormCancel && <Button onClick={onFormCancel}>Cancel</Button>}
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className={classes.button}>
            Add member
          </Button>
        </div>
      </Paper>
    </div>
  );
};


export default styles(MemberCreate);
