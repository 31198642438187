import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  header: {
    textAlign: 'center'
  },
  avatar: {
    margin: theme.spacing() + 'px auto',
    backgroundColor: theme.palette.secondary.main
  }
});

export default withStyles(styles);
