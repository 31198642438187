import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './loading.styles';

function Loading ({ message }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
      <Typography variant="overline" display="block">
        {message}
      </Typography>
    </div>
  );
}

export default Loading;
