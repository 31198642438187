import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
});

export default withStyles(styles);
