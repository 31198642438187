import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import UserForm from '../UserForm';
import styles from './userList.styles.js';

UserList.propTypes = {
  users: PropTypes.array
};

function UserRow ({ user, onSelect }) {
  function handleClick () {
    onSelect(user);
  }

  return (
    <TableRow key={user.uid} onClick={handleClick} hover>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.uid}</TableCell>
    </TableRow>
  );
}

function UserTable ({ users, onSelect }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => <UserRow key={user.uid} user={user} onSelect={onSelect} /> )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UserList ({ users, open, onSelect, onSubmit, onAddClick, onCancel, classes }) {
  return (
     <div className={classes.root}>
       <Paper className={classes.paper}>
          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6">Users</Typography>
                </Grid>

                <Grid item>
                  <Button variant="contained" color="primary" className={classes.addDivision} onClick={onAddClick}>Add user</Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          { open && <UserForm onCancel={onCancel} onSubmit={onSubmit} /> }
          <UserTable users={users} onSelect={onSelect}/>
        </Paper>
      </div>
  )
}

export default styles(UserList);
