import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { db } from 'config';

import { useProvider, useProviderData } from 'hooks/useProvider';
import { useMemberOrg } from 'providers/MembersOrgs';

const OrgStateContext = React.createContext();
const OrgDispatchContext = React.createContext();

function getOrg (memberOrg) {
  const { id, orgRole } = memberOrg;
  return db.collection('orgs').doc(id)
    .get().then((doc) => {
      if (doc.exists) {
        return {
          id,
          orgRole,
          ...doc.data()
        };
      }

      return null;
    });
}

function loadOrgs (memberOrgs, dispatch) {
  const promises = memberOrgs.map(memberOrg => {
    return getOrg(memberOrg);
  });

  dispatch({ type: ORG.FETCH_ORGS_REQUEST });
  return Promise.all(promises).then((orgs) => {
    // filtering out any null returns
    return orgs.filter((org) => org);
  }).then(orgs => dispatch({ type: ORG.FETCH_ORGS_SUCCESS, orgs }))
    .catch(error => dispatch({ type: ORG.FETCH_ORGS_FAILURE, error }));
}

const ORG = {
  FETCH_ORGS_REQUEST: 'FETCH_ORGS_REQUEST',
  FETCH_ORGS_FAILURE: 'FETCH_ORGS_FAILURE',
  FETCH_ORGS_SUCCESS: 'FETCH_ORGS_SUCCESS'
};

function orgDataReducer (state, action) {
  switch (action.type) {
    case ORG.FETCH_ORGS_SUCCESS:
      return action.orgs;
    default:
      return state;
  }
}

function orgErrorReducer (action) {
  switch (action.type) {
    case ORG.FETCH_ORGS_FAILURE:
      return action.error;
    default:
      return null;
  }
}

function orgLoadingReducer (action) {
  switch (action.type) {
    case ORG.FETCH_ORGS_REQUEST:
      return true;
    default:
      return false;
  }
}

function orgReducer (state, action) {
  switch (action.type) {
    case ORG.FETCH_ORGS_REQUEST:
    case ORG.FETCH_ORGS_FAILURE:
    case ORG.FETCH_ORGS_SUCCESS:
      return {
        data: orgDataReducer(state.data, action),
        error: orgErrorReducer(action),
        loading: orgLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for org reducer.');
  };
}

OrgProvider.propTypes = {
  children: PropTypes.node
};

export default function OrgProvider ({ children }) {
  const { data: memberOrgs } = useMemberOrg();
  const { state, dispatch } = useProvider(orgReducer, OrgStateContext, OrgDispatchContext);

  useEffect(() => {
    if (memberOrgs) {
      loadOrgs(memberOrgs, dispatch);
    }
  }, [memberOrgs]);

  return (
    <OrgStateContext.Provider value={state}>
      <OrgDispatchContext.Provider value={dispatch}>
        {children}
      </OrgDispatchContext.Provider>
    </OrgStateContext.Provider>
  );
}

export function useOrg () {
  return useProviderData(OrgStateContext, OrgDispatchContext);
}
