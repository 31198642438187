import { useEffect, useState } from 'react';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

function useForm(initialValues, validators, onSubmit) {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialValues);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitted) {
      onSubmit(values);
      setSubmitted(false);
    }
  }, [submitted, errors, values, onSubmit]);

  function validate(validator, propValue, allValues) {
    return validator && !validator.fn(propValue, allValues) ? true : false;
  }

  function validateAll() {
    const newErrors = reduce(
      values,
      (obj, val, key) => {
        const validator = validators[key];
        let error;

        const validatorArray = !isArray(validator) ? [validator] : validator;

        error = find(validatorArray, v => validate(v, val, values));
        if (error) {
          obj[key] =
            typeof error.message === 'function'
              ? error.message(val)
              : error.message;
        }
        return obj;
      },
      {}
    );

    return newErrors;
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(validateAll(values));
    setSubmitted(true);
  }

  return [handleInputChange, handleSubmit, errors, values];
}

export default useForm;
