import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from 'config';

import Button from '@material-ui/core/Button';

import UserProvider, {  useUser, createUser, promoteUser } from './providers/User';
import MembersOrgsProvider, { useMemberOrg, createMemberOrg, assignMemberOrg } from 'providers/MembersOrgs';
import { useOrg } from 'providers/Org';
import UserList from './components/UserList';
import OrgForm from './components/OrgForm';


function OrgList ({ user }) {
  const { data: orgs } = useOrg();
  const { dispatch } = useMemberOrg();

  function createOrg (org) {
    createMemberOrg(org, user, dispatch);
  }

  function handleAssign (org) {
    assignMemberOrg(org, user, dispatch);
  }

  return (
    <>
      { orgs.map(org => <div key={org.id}>{org.name}</div>) }
      <OrgForm onSubmit={createOrg} onAssign={handleAssign} />
    </>
  )
}

function UserDetails ({ user, onPromote, onSelect }) {
  const isAdmin = user.customClaims && user.customClaims.admin;

  function handleClick () {
    onPromote(user);
  }

  return (
    <div>
      <h3>{user.displayName}</h3>
      { isAdmin
        ? <strong>Admin</strong>
        : <Button onClick={handleClick}>Make Admin</Button> }

      <MembersOrgsProvider uid={user.uid}>
        <OrgList user={user} onSelect={onSelect} />
      </MembersOrgsProvider>
    </div>
  )
}

function List () {
  const [user, setUser] = useState(null);
  const [active, setActive] = useState(null);
  const [open, setOpen] = useState(false);
  const { data: users, loading, dispatch } = useUser();
  useEffect(() => {
    const updated = users.find(u => u.uid === user);
    setActive(updated);
  }, [user, users])

  function handleAddClick () {
    setOpen(true);
  }

  function hideForm () {
    setOpen(false);
  }

  function handleSubmit (user) {
    createUser(user, dispatch);
    hideForm();
  }

  function handlePromote (user) {
    promoteUser(user, dispatch);
  }

  function handleSelect (user) {
    setUser(user.uid);
  }

  function handleAddUser (org) {
    console.log(org);
  }

  return (
    (loading)
      ? <span>Loading Users</span>
      : (!users)
          ? <span>No Users Found</span>
          : <>
              <UserList
                users={users}
                open={open}
                onAddClick={handleAddClick}
                onCancel={hideForm}
                onSelect={handleSelect}
                onSubmit={handleSubmit} />

              { active && <UserDetails user={active} onPromote={handlePromote} onSelect={handleAddUser} /> }
            </>
  )
}

function Admin ({ user }) {
  return (
    <UserProvider>
      <List />
    </UserProvider>
  );
}

export default Admin;
