import React, { useEffect, useReducer, useContext } from 'react';
import { fetch } from 'config';
import { PropTypes } from 'prop-types';

import { db } from 'config';

import { useProvider, useProviderData } from 'hooks/useProvider';
import OrgProvider from '../Org';

const MembersOrgsStateContext = React.createContext();
const MembersOrgsDispatchContext = React.createContext();

const MEMBERS_ORGS = {
  FETCH_ORGS_REQUEST: 'FETCH_ORGS_REQUEST',
  FETCH_ORGS_FAILURE: 'FETCH_ORGS_FAILURE',
  FETCH_ORGS_SUCCESS: 'FETCH_ORGS_SUCCESS',
  CREATE_ORG_REQUEST: 'CREATE_ORG_REQUEST',
  CREATE_ORG_FAILURE: 'CREATE_ORG_FAILURE',
  CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',
  ASSIGN_ORG_REQUEST: 'ASSIGN_ORG_SUCCESS',
  ASSIGN_ORG_FAILURE: 'ASSIGN_ORG_FAILURE',
  ASSIGN_ORG_SUCCESS: 'ASSIGN_ORG_SUCCESS'
};

function loadMembersOrgs (userId) {
  return db.collection(`members_orgs/${userId}/orgs`).get()
    .then(query => query.docs.map(doc => ({ id: doc.id, ...doc.data() })));
};

export function createMemberOrg (org, user, dispatch) {
  return fetch.post('/admin/orgs', { org, user })
    .then(({data }) => dispatch({ type: MEMBERS_ORGS.CREATE_ORG_SUCCESS, org: data }))
    .catch(error => dispatch({ type: MEMBERS_ORGS.CREATE_ORG_FAILURE, error }))
}

export function assignMemberOrg (org, user, dispatch) {
  return fetch.post(`/admin/orgs/${org.id}/users`, { user })
    .then(({ data }) => dispatch({ type: MEMBERS_ORGS.ASSIGN_ORG_SUCCESS, org: data }))
    .catch(error => dispatch({ type: MEMBERS_ORGS.ASSIGN_ORG_FAILURE, error }))
}

function membersOrgsErrorReducer (action) {
  switch (action.type) {
    case MEMBERS_ORGS.FETCH_ORGS_FAILURE:
    case MEMBERS_ORGS.CREATE_ORG_FAILURE:
      return action.error;
    default:
      return null;
  }
}

function membersOrgsLoadingReducer (action) {
  switch (action.type) {
    case MEMBERS_ORGS.FETCH_ORGS_REQUEST:
      return true;
    default:
      return false;
  }
}

function membersOrgsDataReducer (state, action) {
  switch (action.type) {
    case MEMBERS_ORGS.FETCH_ORGS_SUCCESS:
      return action.orgs;
    case MEMBERS_ORGS.CREATE_ORG_SUCCESS:
      return state.concat([action.org]);
    case MEMBERS_ORGS.ASSIGN_ORG_SUCCESS:
      return state.find(org => org.id === action.org.id)
        ? state
        : state.concat([action.org])
    default:
      return state;
  }
}

function membersOrgsReducer (state, action) {
  switch (action.type) {
    case MEMBERS_ORGS.FETCH_ORGS_REQUEST:
    case MEMBERS_ORGS.FETCH_ORGS_SUCCESS:
    case MEMBERS_ORGS.FETCH_ORGS_FAILURE:
    case MEMBERS_ORGS.CREATE_ORG_REQUEST:
    case MEMBERS_ORGS.CREATE_ORG_SUCCESS:
    case MEMBERS_ORGS.CREATE_ORG_FAILURE:
    case MEMBERS_ORGS.ASSIGN_ORG_REQUEST:
    case MEMBERS_ORGS.ASSIGN_ORG_SUCCESS:
    case MEMBERS_ORGS.ASSIGN_ORG_FAILURE:
      return {
        ...state,
        data: membersOrgsDataReducer(state.data, action),
        error: membersOrgsErrorReducer(action),
        loading: membersOrgsLoadingReducer(action)
      }
    default:
      throw Error('Unknown action type for members orgs reducer.');
  };
};

export default function MembersOrgsProvider ({ children, uid }) {
  const { state, dispatch } = useProvider(membersOrgsReducer, MembersOrgsStateContext, MembersOrgsDispatchContext);
  useEffect(() => {
    dispatch({ type: MEMBERS_ORGS.FETCH_ORGS_REQUEST });

    loadMembersOrgs(uid)
      .then(orgs => {
        dispatch({ type: MEMBERS_ORGS.FETCH_ORGS_SUCCESS, orgs });
      })
      .catch(error => {
        dispatch({ type: MEMBERS_ORGS.FETCH_ORGS_FAILURE, error });
      });
  }, [uid]);

  /* this is causing refresh to always go back to the label list */

  return (
    <MembersOrgsStateContext.Provider value={state}>
      <MembersOrgsDispatchContext.Provider value={dispatch}>
        <OrgProvider>
          {children}
        </OrgProvider>
      </MembersOrgsDispatchContext.Provider>
    </MembersOrgsStateContext.Provider>
  );
}

export function useMemberOrg () {
  return useProviderData(MembersOrgsStateContext, MembersOrgsDispatchContext);
}
