import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%'
  },
  loader: {
    color: '#FD46E5'
  }
});

export default makeStyles(styles);
