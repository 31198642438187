import withStyles, { fade } from '@material-ui/core/styles/withStyles';
const styled = theme => ({
  gutters: {
    padding: '20px 45px',
  }
});

const divider = theme => ({
  root: {
    width: 1,
    height: 40,
    backgroundImage: 'linear-gradient(180deg, #262733 0%, #4F5362 18%, #4F5362 81%, #262734 100%)'
  }
});

const search = theme => ({
  root: {
  },
  input: {
    color: '#7A838E',
    fontSize: '20px',
    minWidth: 300,
    padding: 12,
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    width: '100%',
    '&::placeholder': {
      opacity: 1
    }
  },
  search: {
    position: 'relative',
    borderRadius: '30px',
    backgroundColor: '#333643',
    width: '100%',
  },
  searchIcon: {
    padding: '0 12px 0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const styleSearch = withStyles(search)
export const styleDivider = withStyles(divider)
export default withStyles(styled);
