
import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import InputBase from '@material-ui/core/InputBase';
import UserMenu from '../UserMenu/UserMenu.component';
import VertDivider from 'components/VertDivider';
import { ReactComponent as Mark } from './mark.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as AlertIcon } from './alert.svg';
import { ReactComponent as GearIcon } from './gear.svg';
import { ReactComponent as GroupIcon } from './group.svg';
import { ReactComponent as UploadIcon } from './upload.svg';

import styled, { styleSearch, styleDivider } from './userToolbar.styles';

UserToolbar.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  onTriggerClick: PropTypes.func
}

function Search ({ classes }) {
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.root,
          input: classes.input
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
}

const StyledSearch = styleSearch(Search);

function UserToolbar ({ user, classes, children, onTriggerClick }) {
  return (
    <Toolbar classes={classes}>
      <Grid container spacing={4} alignItems="center">
        <Hidden smUp>
          <Grid item>

            { onTriggerClick && <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onTriggerClick}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton> }

            { !onTriggerClick && <Mark /> }

          </Grid>
        </Hidden>

        <Hidden xsDown>
          <Grid item>
            <Logo />
          </Grid>
        </Hidden>

        <Grid item>
          <VertDivider variant="dark"/>
        </Grid>

        <Grid item>
          <StyledSearch />
        </Grid>

        <Grid item xs />

        <Grid item>
          <GroupIcon />
        </Grid>

        <Grid item>
          <GearIcon />
        </Grid>

        <Grid item>
          <UploadIcon />
        </Grid>

        <Grid item>
          <AlertIcon />
        </Grid>

        <Grid item>
          <VertDivider variant="dark"/>
        </Grid>

        <Grid item>
          <UserMenu user={user} />
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default styled(UserToolbar);
