import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  }
});

export default theme;
