import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Snackbar from '@material-ui/core/Snackbar';

import { auth } from 'config';
import { useSessionState } from 'providers/Session';
import LoginCard from './components/LoginCard';
import LoginForm from './components/LoginForm';
import style from './login.styles';


Login.propTypes = {
  classes: PropTypes.object.isRequired
};

function Login ({ classes }) {
  const [open, setOpen] = useState(false);
  const session = useSessionState();

  function createSession ({ email, password }) {
    auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        // TODO: display the message.
        setOpen(true);
      });
  }

  function handleError (errors) {
    setOpen(true);
  }

  function handleClose () {
    setOpen(false);
  }

  if (session && session.user) return <Redirect to="/orgs" />
  return (
    <div className={classes.root}>
      <LoginCard>
        <LoginForm onSubmit={createSession} onError={handleError} />
      </LoginCard>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span>Oops! Please fix the marked errors.</span>}
      />
    </div>
  );
}

export default style(Login);
