import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { fetch, db } from 'config';

const MemberStateContext = React.createContext();
const MemberDispatchContext = React.createContext();

function loadMembers (org, dispatch) {
  dispatch({ type: MEMBER.FETCH_MEMBERS_REQUEST });
  const { id: orgId } = org;

  return db.collection('orgs_members').doc(orgId)
    .collection('members').get().then(query => {
      const docs = [];
      query.forEach(doc => docs.push({ id: doc.id, ...doc.data() }));
      return docs;
    }).then(members => dispatch({ type: MEMBER.FETCH_MEMBERS_SUCCESS, members }))
      .catch(error => dispatch({ type: MEMBER.FETCH_MEMBERS_FAILURE, error }));
}

export function createMember (member, orgId, dispatch) {
  dispatch({ type: MEMBER.CREATE_MEMBER_REQUEST });
  fetch.post(`/orgs/${orgId}/members`, { member })
    .then(({ data }) => {
      dispatch({ type: MEMBER.CREATE_MEMBER_SUCCESS, member: data });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: MEMBER.CREATE_MEMBER_FAILURE, error });
    });
}

const MEMBER = {
  FETCH_MEMBERS_REQUEST: 'FETCH_MEMBERS_REQUEST',
  FETCH_MEMBERS_FAILURE: 'FETCH_MEMBERS_FAILURE',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
  CREATE_MEMBER_REQUEST: 'CREATE_MEMBER_REQUEST',
  CREATE_MEMBER_FAILURE: 'CREATE_MEMBER_FAILURE',
  CREATE_MEMBER_SUCCESS: 'CREATE_MEMBER_SUCCESS'
};

function memberDataReducer (state, action) {
  switch (action.type) {
    case MEMBER.FETCH_MEMBERS_REQUEST:
      return undefined;
    case MEMBER.FETCH_MEMBERS_FAILURE:
      return null;
    case MEMBER.FETCH_MEMBERS_SUCCESS:
      return action.members;
    case MEMBER.CREATE_MEMBER_SUCCESS:
      return state.concat([action.member]);
    default:
      return state;
  }
}

function memberReducer (state, action) {
  switch (action.type) {
    case MEMBER.FETCH_MEMBERS_REQUEST:
    case MEMBER.FETCH_MEMBERS_FAILURE:
    case MEMBER.FETCH_MEMBERS_SUCCESS:
    case MEMBER.CREATE_MEMBER_REQUEST:
    case MEMBER.CREATE_MEMBER_FAILURE:
    case MEMBER.CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        data: memberDataReducer(state.data, action)
      }
    default:
      throw Error('Unknown action type for member reducer.');
  };
}

MemberProvider.propTypes = {
  children: PropTypes.node,
  org: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default function MemberProvider ({ org, children }) {
  const [member, dispatch] = useReducer(memberReducer, {
    data: undefined
  });

  useEffect(() => {
    loadMembers(org, dispatch);
  }, [org]);

  return (
    <MemberStateContext.Provider value={member}>
      <MemberDispatchContext.Provider value={dispatch}>
        {children}
      </MemberDispatchContext.Provider>
    </MemberStateContext.Provider>
  );
}

export function useMemberState () {
  return useContext(MemberStateContext);
}

export function useMemberDispatch () {
  return useContext(MemberDispatchContext);
}
