import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  contentWrapper: {
    margin: '40px 16px',
  },
  rowLink: {
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none'
  }
});

export default withStyles(styles);
