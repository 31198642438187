import withStyles from '@material-ui/core/styles/withStyles';

const styled = theme => ({
  root: {
    backgroundImage: 'linear-gradient(90deg, #2E2F3E 1%, #0A0B10 100%)',
    zIndex: theme.zIndex.drawer + 1
  }
});

export default withStyles(styled);
