import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    backgroundColor: '#F9F9FA',
    flex: 1,
    display: 'flex'
  },
});

export default makeStyles(styles);
