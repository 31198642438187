import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';

import UserToolbar from './UserToolbar/UserToolbar.component';
import { useSessionState } from 'providers/Session';

import styled from './userHeader.styles';

UserHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  onMenuInteraction: PropTypes.func
};

function UserHeader ({org, session, classes, children, onMenuInteraction}) {
  const { user } = useSessionState();

  return (
    (user)
      ? <AppBar
        position="sticky"
        classes={classes}
        elevation={0}>
        <UserToolbar user={user} onTriggerClick={onMenuInteraction}>
          {children}
        </UserToolbar>
      </AppBar>
      : null
  );
}

export default styled(UserHeader);
